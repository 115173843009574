<template>
    <div>
        <section class="innerBanner pt-1">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="back-btn mb-4">
                            <router-link to="/">
                                <span class="backIcon">
                                    <span class="icon-arrow"></span>
                                </span>
                                Back
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Checkout</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="pageWrap checkoutWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8">
                        <div class="cartList ">
                            <h2>Order Summary</h2>
                                <ul>
                                    <li>
                                        <div class="custom-checkbox mt-3 me-3">
                                            <input type="checkbox" id="html">
                                            <label for="html"></label>
                                        </div>
                                        <div class="itemImage">
                                            <img src="../assets/images/image1.png" alt="">
                                        </div>
                                        <div class="itemContent">
                                            <router-link to="">
                                                <div>
                                                    <h3>Davinci ResolveDavinci ResolveDavinci Resolve</h3>
                                                    <p>Type: <span class="brand-color">Course</span></p>
                                                    <span class="price">NPR: 999</span>
                                                </div>
                                            </router-link>
                                            <div class="cartRemove">
                                                <span class="icon-trash"></span>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-checkbox mt-3 me-3">
                                            <input type="checkbox" id="html">
                                            <label for="html"></label>
                                        </div>
                                        <div class="itemImage">
                                            <img src="../assets/images/image1.png" alt="">
                                        </div>
                                        <div class="itemContent">
                                            <router-link to="">
                                                <div>
                                                    <h3>Davinci ResolveDavinci ResolveDavinci Resolve</h3>
                                                    <p>Type: <span class="brand-color">Course</span></p>
                                                    <span class="price">NPR: 999</span>
                                                </div>
                                            </router-link>
                                            <div class="cartRemove">
                                                <span class="icon-trash"></span>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-checkbox mt-3 me-3">
                                            <input type="checkbox" id="html">
                                            <label for="html"></label>
                                        </div>
                                        <div class="itemImage">
                                            <img src="../assets/images/image1.png" alt="">
                                        </div>
                                        <div class="itemContent">
                                            <router-link to="">
                                                <div>
                                                    <h3>Davinci ResolveDavinci ResolveDavinci Resolve</h3>
                                                    <p>Type: <span class="brand-color">Course</span></p>
                                                    <span class="price">NPR: 999</span>
                                                </div>
                                            </router-link>
                                            <div class="cartRemove">
                                                <span class="icon-trash"></span>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="custom-checkbox mt-3 me-3">
                                            <input type="checkbox" id="html">
                                            <label for="html"></label>
                                        </div>
                                        <div class="itemImage">
                                            <img src="../assets/images/image1.png" alt="">
                                        </div>
                                        <div class="itemContent">
                                            <router-link to="">
                                                <div>
                                                    <h3>Davinci ResolveDavinci ResolveDavinci Resolve</h3>
                                                    <p>Type: <span class="brand-color">Course</span></p>
                                                    <span class="price">NPR: 999</span>
                                                </div>
                                            </router-link>
                                            <div class="cartRemove">
                                                <span class="icon-trash"></span>
                                                
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="display-card">
                            <h3>Have Coupon?</h3>
                            <input type="text" placeholder="Enter Your Coupon Code" class="univ-input" name="" id="">
                            <button class="univ-dark-btn py-2 mt-3">Apply Coupon</button>
                            <hr>
                            <h3>Order Summary</h3>
                            <ul class="orderSummary">
                                <li>
                                    <span>Order Price</span>
                                    <span>999</span>
                                </li>
                                <li>
                                    <span>Coupon Discount</span>
                                    <span>0</span>
                                </li>
                                <li>
                                    <strong>Total</strong>
                                    <strong class="brand-color">999</strong>
                                </li>
                            </ul>
                            <br>
                            <h3>Payment Via</h3>
                            <div class="row">
                                <div class="col-xl-4 col-6 my-3">
                                    <div class="paymentBlock py-3">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png" alt="">
                                    </div>
                                </div>
                                <div class="col-xl-4 col-6 my-3">
                                    <div class="paymentBlock py-3">
                                        <img src="https://seeklogo.com/images/E/esewa-logo-DA36F8FD2F-seeklogo.com.png" alt="">
                                    </div>
                                </div>
                                <div class="col-xl-4 col-6 my-3">
                                    <div class="paymentBlock py-3">
                                        <img src="https://seeklogo.com/images/K/khalti-logo-F0B049E67E-seeklogo.com.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <button class="univ-btn w-100 mt-4" @click="showPaymentWrap = true">Proceed </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'Checkout'
}
</script>